import * as React from "react"
import Layout from "../components/Layout"
import Features from "../components/Products/Products"
import Seo from "../components/SEO"
import { BlogSingleStyles } from "../components/Info/InfosStyles"


const products = () => {
  return (
    <>
      <Seo title="Products" />
      <Layout>     
        <section>
          <BlogSingleStyles>
            <h1 className="blogsingle__title">Продуктов каталог</h1>
            <article className="blogsingle__content">

            </article>
          </BlogSingleStyles>
        </section>
        <Features />      
      </Layout>
    </>
  )
}

export default products
